/* eslint-disable no-eval */
import React, { useEffect, useState } from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  Button,
  List,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
  withStyles,
} from "@material-ui/core/";
import { green } from "@material-ui/core/colors";
import Ionicon from "react-ionicons";
import Loader from "react-loader-spinner";

// Configs
import { BASE_URL } from "../app.config";
import InputField from "../commons/input.field";
import { phoneSanitizer } from "../utils/other/sanitizers";
import { post, put } from "../utils/fetch";
import moment from "moment-timezone";
import Ecocash from "./payment/ecocash";
import logger from "../utils/logger";

const Payment = (props) => {
  const [value, setValue] = useState("");
  const [option, setOption] = useState();
  const [input_parameter, setInputParameter] = useState({});
  const [param, setParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [ecocash, setEcocash] = useState(false);
  const [smsNumber, setSmsNumber] = useState("");
  const [proceedMessage, setProceedMessage] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState("Soonest possible");
  const [availableDueDates, setAvailableDueDates] = useState([]);

  useEffect(() => {
    const getAvailableDueDates = async () => {
      const dateTime = moment().format("YYYY-MM-DD HH:mm:ss");

      const body = {
        complex_uuid: props.complex.uuid,
        date_time: dateTime.toString(),
      };

      const url = `${BASE_URL}/complexes/get_order_times?app=ao`;

      const response = await post(url, body);

      if (response) {
        if (response.length > 0) {
          setAvailableDueDates([
            { label: "Soonest possible", value: "Soonest possible" },
            ...response,
          ]);
        }
      }
    };

    setSmsNumber(props.phone.replace("+", ""));
    getAvailableDueDates();
  }, [props.phone, props.complex.uuid]);

  const postPayment = async (url, data) => {
    const response = await post(url, data);

    if (response.success) {
      onPayment();
    } else {
      setProceedMessage({
        title: "Payment Error",
        body: response.message ?? "Error processing payment",
        bgcolour: "#d71920",
      });

      setLoading(false);
    }
  };

  const addParameter = (parameter, value) => {
    let key = parameter.key;
    let in_param = input_parameter;
    in_param[key] = value;
    setInputParameter({ ...in_param });
  };

  const onPayment = async () => {
    if (option.id) {
      let is_confirm = true;

      try {
        for (let index = 0; index < props.cart.length; index++) {
          const item = props.cart[index];
          if (item.prize_id) {
            const body = {
              phone: props.phone,
              prize_id: item.prize_id,
              order_uuid: props.orderUUID,
            };

            logger("ao_onPayment", "cp_Req", props.orderUUID, body);

            const res = await post(`${BASE_URL}/claim_price`, body);

            logger("ao_onPayment", "cp_Res", props.orderUUID, res);

            if (!res.prize_id) {
              props.setShowFeedback(true);
              is_confirm = false;
              props.setFeedback(res);
              setLoading(false);
              break;
            }
          }
        }
      } catch (error) {
        const p = {
          msg: error.message,
        };

        logger("ao_onPayment", "cp_Catch", props.orderUUID, p);
      }

      const details = props.customer_details;
      const { directions, instructions, kra_pin, source, alt_phone } = details;

      logger("ao_onPayment", "cm_Init", props.orderUUID, {
        option,
        is_confirm,
      });

      if (option.type === "online" && !is_confirm) {
        is_confirm = true;
      }

      if (is_confirm) {
        const payload = {
          phone: props.phone,
          due_date: selectedDueDate,
          payment: {
            id: option.id,
            parameters: input_parameter,
            payment_brand: option.payment_brand || "",
            type: option.type,
            currency: props.currency,
          },
        };

        if (alt_phone) {
          payload.alt_phone = alt_phone;
        }

        if (instructions) {
          payload.address_instruction = instructions;
        }

        if (directions) {
          payload.directions = directions;
        }

        if (kra_pin) {
          payload.kra_pin = kra_pin;
        }

        if (source) {
          payload.order_source = source;
        }

        logger("ao_onPayment", "cm_Req", props.orderUUID, {
          payload,
          option,
        });

        if (props.currency === option.currency) {
          put(`${BASE_URL}/cart/${props.orderUUID}`, payload).then(
            (response) => {
              logger("ao_onPayment", "cm_Res", props.orderUUID, {
                payload,
                option,
                response,
              });

              if (response.checkout) {
                setProceed(response.checkout);
                props.complete(true, response.order.odos_order_number);
                setLoading(false);
              } else {
                setProceed(response.checkout);
                setProceedMessage(response.message);
                setLoading(false);
                props.hideBackBtn(false);
              }
            }
          );
        } else {
          setProceed(false);
          setProceedMessage({
            title: "Mismatch Payment Currency",
            body: "Please contact support",
          });

          setLoading(false);
          props.hideBackBtn(false);
        }
      }
    } else {
      setProceed(false);
      setProceedMessage({
        title: `Payment ${option.name.en} ID is missing`,
        body: "Please contact support",
      });
      setLoading(false);
      props.hideBackBtn(false);
    }
  };

  const onAddPayment = async () => {
    try {
      await put(`${BASE_URL}/cart/add_payment/${props.orderUUID}`, {
        phone: props.phone,
        payment_state: "Awaiting Payment",
        kra_pin: props.customer_details.kra_pin,
        payment: option,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const confirm = () => {
    let in_params = [];
    if (option) {
      if (Object.values(input_parameter).length) {
        in_params.push(input_parameter);
      }

      if (option.parameters) {
        let rps = option.parameters.filter((f) => f.required === true);

        if (
          in_params.length !== rps.length &&
          rps.length > 0 &&
          rps[0].required === true
        ) {
          setProceedMessage({
            title: {
              en: "Invalid field input",
            },
            description: {
              en: "Please enter valid input",
            },
            bgcolour: "#d71920",
          });
          return false;
        }
      }

      if (option.type === "online") {
        if (!smsNumber) {
          return setProceedMessage({
            title: "Payment Error",
            body: "Please fill in your sms number",
            bgcolour: "#d71920",
          });
        }

        setLoading(true);
        props.hideBackBtn(true);

        if (option.payment_brand === "ecocash") {
          onAddPayment();

          return setEcocash(true);
        }

        const sms_number = phoneSanitizer(props.country.dial_code + smsNumber);

        let url;
        const body = {
          order_uuid: props.orderUUID,
          msisdn: sms_number.replace("+", ""),
        };

        if (option.payment_brand === "paybylink") {
          url = `${BASE_URL}/cellulant/create_bill`;
        } else if (option.payment_brand === "innbucks_ussd") {
          url = `${BASE_URL}/innbucks/merchant/process_payment`;
        } else if (option.payment_brand === "pesapal_paybylink") {
          url = `${BASE_URL}/pesapal/process_payment`;
        } else if (option.payment_brand === "peach_paybylink") {
          url = `${BASE_URL}/peach/paybylink/process_payment`;
        } else {
          setLoading(false);
        }

        if (url) {
          postPayment(url, body);
        }
      } else {
        setLoading(true);
        props.hideBackBtn(true);
        onPayment();
      }
    } else {
      setProceedMessage({
        title: "Payment Error",
        body: "Please choose a Payment Option",
        bgcolour: "#d71920",
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const _opt = JSON.parse(e.target.value);
    setValue(e.target.value);
    setOption(_opt);
  };

  const onClose = async () => {
    setEcocash(false);
    setLoading(false);
    props.hideBackBtn(false);

    const url = `${BASE_URL}/order/remove_pending_order`;
    const payload = {
      order_uuid: props.orderUUID,
      phone: props.phone,
    };

    await post(url, payload);
  };

  return (
    <div>
      {proceedMessage && (
        <ListItem
          style={{
            backgroundColor: `${proceedMessage?.bgcolour}`,
            borderRadius: 4,
            marginBottom: 2,
          }}
        >
          <ListItemIcon>
            <Ionicon
              icon="ios-information-circle-outline"
              color="#000"
              fontSize="36px"
            />
          </ListItemIcon>
          <ListItemText
            primary={proceedMessage?.title}
            secondary={proceedMessage?.body}
          />
        </ListItem>
      )}

      <List
        style={{
          paddingBottom: "64px",
          marginLeft: 16,
          marginRight: 16,
          zIndex: 999,
          marginTop: 30,
        }}
      >
        {availableDueDates.length > 0 && (
          <main style={{ marginBottom: 20 }}>
            <div
              style={{
                paddingLeft: 15,
                paddingBottom: 10,
                paddingTop: 10,
                fontWeight: "bold",
              }}
            >
              Choose Delivery Time
            </div>

            {availableDueDates.length > 0 && (
              <select
                className="select-field"
                style={{
                  width: "calc(100% - 15px)",
                  margin: 8,
                }}
                onChange={({ target }) => {
                  setSelectedDueDate(target.value);
                }}
                defaultValue="Soonest possible"
                value={selectedDueDate}
              >
                {availableDueDates.map(({ label, value }, index) => (
                  <option
                    className="action-option"
                    value={value}
                    key={value + index}
                  >
                    {label}
                  </option>
                ))}
              </select>
            )}
          </main>
        )}
        {props.payments.map((opt) => (
          <RadioGroup key={opt.id} value={value} onChange={handleChange}>
            <FormControlLabel
              key={opt.id}
              value={JSON.stringify(opt)}
              control={<GreenRadio />}
              label={opt.name.en}
            />
          </RadioGroup>
        ))}
        {option ? (
          <List style={{ marginTop: 8 }}>
            {(option.parameters || []).map((parameter, i) => (
              <InputField
                key={i}
                placeholder={parameter.name.en}
                value={param}
                required={parameter.required ? true : false}
                setValue={(event) => {
                  setParam(event);
                  if (parameter.required && eval(parameter.regex).test(value)) {
                    addParameter(parameter, event);
                  } else {
                    if (!parameter.required) {
                      addParameter(parameter, event);
                    }
                  }
                }}
              />
            ))}

            {option.type === "online" && (
              <InputField
                placeholder={`Enter ${
                  option.payment_brand === "ecocash" ? "ECOCASH" : "SMS"
                } number`}
                value={smsNumber}
                required={true}
                setValue={(event) => setSmsNumber(event)}
              />
            )}
          </List>
        ) : null}
      </List>
      <div className="confirm">
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Loader type="ThreeDots" color="#D71920" height="100" width="100" />
          </div>
        ) : proceed ? (
          <div style={{ textAlign: "center" }}>
            <Ionicon
              style={{
                fill: "green",
                width: 40,
                height: 40,
              }}
              icon="md-checkmark-circle"
            />
          </div>
        ) : (
          props.enablePayment && (
            <Button
              fullWidth={true}
              onClick={confirm}
              disabled={props.enablePayment ? false : true}
              variant="contained"
              style={{
                backgroundColor: props.enablePayment ? "#D71920" : "#cccccc",
                borderRadius: 0,
              }}
            >
              <ListItemText
                disableTypography
                style={{ margin: 0 }}
                primary={
                  <Typography
                    type="body2"
                    style={{
                      color: "#fff",
                      textTransform: "capitalize",
                    }}
                  >
                    Confirm & Place Order
                  </Typography>
                }
              />
            </Button>
          )
        )}
      </div>
      {ecocash && (
        <Ecocash
          order_uuid={props.orderUUID}
          number={smsNumber}
          open={ecocash}
          onClose={onClose}
          onSuccess={onPayment}
        />
      )}
    </div>
  );
};

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default Payment;
