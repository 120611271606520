/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  Popover,
  DialogContent,
  Slide,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faMotorcycle,
  faUsers,
  faCheckSquare,
  faCheckCircle,
  faEllipsisV,
  faUser,
  faChartBar,
  faDollarSign,
  faCalendar,
  faCheck,
  faUserEdit,
  faStickyNote,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";
import Ionicon from "react-ionicons";
import Print from "rc-print";
import Loader from "react-loader-spinner";
import appicon from "../assets/dadv2.png";
import moment from "moment-timezone";

// Config

import Progress from "./progress";
import { useSelector } from "react-redux";

library.add(
  faMotorcycle,
  faUsers,
  faCheckSquare,
  faCheckCircle,
  faEllipsisV,
  faUser,
  faChartBar,
  faCalendar,
  faDollarSign,
  faCheck,
  faUserEdit,
  faCircle,
  faStickyNote,
  faRoad
);
const useStyles = makeStyles((theme) => ({
  dialog: {
    boxShadow: "none",
    borderRadius: 0,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDialog = ({ onClose, open, order, callingNumber, tax }) => {
  const classes = useStyles();
  let printRef = null;

  const agent = useSelector((state) => state.auth.user);
  const [anchorHistoryEl, setAnchorHistoryEl] = useState(null);
  const [isPushing, setIsPushing] = useState(false);

  const getCurrentState = (order) => {
    if (order.states) {
      let currentState = order.states[0];
      order.states.map((s) => {
        if (s.completedTime !== "0:00") currentState = s;
      });
      return currentState;
    }

    return {
      stateName: "",
    };
  };

  const sendToOdos = async () => {
    if (!isPushing) {
      const url = `${order.base_url}/rest/order/${order.complex_uuid}`;
      const country_tz = `Africa/${order.customer.city}`.replaceAll(" ", "");

      setIsPushing(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          AUTHORIZATION: "y_bHqy-Y5wor07I4tFMuCh2PbUdBR_fhWkFKC8-JyAo",
          TOKEN: "EROmYghE2wZgdGIOgAmliHXJv-kf57eIfVjOJ7ROq2c",
        },
        body: JSON.stringify({
          ...order,
          placed_from: "AO",
          order_status: "Queued",
          payment_state: "Paid",
          pushed_by: agent.email,
          pushed_at: moment().tz(country_tz).format("YYYY-MM-DD HH:mm:ss"),
        }),
      });

      const result = await response.json();
      if (result) {
        setIsPushing(false);

        if (result.order_status !== "Declined") {
          onClose();
        }
      }
    }
  };

  const renderLineItems = () => {
    let _lineItem, display;
    let displays = [];

    if (order && order.line_items) {
      _lineItem = order.line_items.map((lineitem) => lineitem);
    }

    if (_lineItem) {
      display = _lineItem.map((lineitem, i) => (
        <ListItem
          key={lineitem.id + i}
          style={{
            borderBottom: "thin solid #dddddd",
            paddingBottom: 8,
            padding: 0,
            paddingTop: 8,
          }}
        >
          <Grid item sm={12} style={{ paddingBottom: 8 }}>
            <Grid container style={{ fontWeight: 550, paddingBottom: 8 }}>
              <Grid sm={6} item>
                {lineitem.name.en || lineitem.name || ""}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {Math.round(lineitem.price) !== 0 ? lineitem.price : null}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {Math.round(lineitem.price) !== 0
                  ? Math.round(lineitem.qty)
                  : null}
              </Grid>
              <Grid sm={2} item style={{ textAlign: "center" }}>
                {lineitem.total
                  ? lineitem.total
                  : Math.round(lineitem.price) !== 0
                  ? lineitem.price
                  : null}
              </Grid>
            </Grid>
            {lineitem.addons &&
              lineitem.addons.map((addon, i) => (
                <Grid container key={i}>
                  <Grid item sm={6}>
                    {addon.name.en || addon.name || ""}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {addon.price !== 0 ? addon.price : null}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {Math.round(addon.qty)}
                  </Grid>
                  <Grid item sm={2} style={{ textAlign: "center" }}>
                    {addon.total !== 0 ? addon.total : null}
                  </Grid>
                </Grid>
              ))}

            {lineitem.instructions ? (
              <Grid item sm={12}>
                {lineitem.instructions}
              </Grid>
            ) : null}
          </Grid>
        </ListItem>
      ));
      displays.push(display);
    }

    return displays;
  };

  const renderPrintItems = () => {
    let lineItemBrand = [];
    let _lineItem, display;
    let displays = [];

    if (order && order.line_items) {
      _lineItem = order.line_items.map((lineitem, i) => {
        lineItemBrand.push(lineitem.brand);
        return lineitem;
      });
    }

    if (lineItemBrand) {
      let brands = lineItemBrand.filter(
        (item, index) => lineItemBrand.indexOf(item) >= index
      );

      for (let brand of brands) {
        display = brand && (
          <div
            key={brand}
            style={{
              width: "100%",
              marginTop: "32px",
              borderCollapse: "collapse",
              borderBottom: "1px solid black",
            }}
          >
            <div
              style={{ textAlign: "left", fontSize: 15, fontWeight: "bold" }}
            >
              {brand.name?.en ?? brand.en ?? brand}
            </div>
            {_lineItem
              .filter((f) => f.brand === brand)
              .map((lineitem, i) => (
                <div key={i}>
                  <div
                    style={{
                      margin: 4,
                      fontWeight: "bold",
                      display: "grid",
                      gridTemplateColumns: "auto 100px 50px 100px",
                    }}
                  >
                    <div>{lineitem.name.en || lineitem.name || ""}</div>
                    <div>{lineitem.price !== 0 ? lineitem.price : null}</div>
                    <div>{Math.round(lineitem.qty)}</div>
                    <div>{lineitem.total}</div>
                  </div>
                  {lineitem.addons &&
                    lineitem.addons.map((addon, i) => (
                      <div
                        key={i}
                        style={{
                          margin: 4,
                          fontWeight: "bold",
                          display: "grid",
                          gridTemplateColumns: "auto 100px 50px 100px",
                        }}
                      >
                        <div>{addon.name.en || addon.name || ""}</div>
                        <div>{addon.price !== 0 ? addon.price : null}</div>
                        <div>{addon.qty}</div>
                        <div>{addon.total !== 0 ? addon.total : null}</div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        );
        displays.push(display);
      }
    }

    return displays;
  };

  const state = () => {
    if (order) {
      let order_states = [];
      if (order.states) {
        order_states = order.states;
      }

      if (order_states) {
        const currentState = getCurrentState(order)
          ? getCurrentState(order)
          : order.order_status;

        const nextSate = order_states[order_states.indexOf(currentState) + 1];
        const states = [currentState, nextSate];

        return { states, currentState, order_states };
      }
    }
  };

  return order ? (
    <Dialog
      onClose={() => onClose(false)}
      className={classes.dialog}
      maxWidth="sm"
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          width: "100%",
        },
      }}
    >
      <DialogTitle
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 48,
          paddingRight: 48,
        }}
      >
        <div align="right" style={{ marginTop: 5, marginRight: -40 }}>
          <Ionicon
            icon="ios-close-circle-outline"
            onClick={() => onClose(false)}
          />
        </div>
        <Grid container style={{ fontSize: "0.9rem", marginTop: -10 }}>
          <Grid item sm={10}>
            <List>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.name}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText style={{ margin: 0 }} primary={callingNumber} />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.address}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.customer.address_instructions}
                />
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={
                    <div>
                      <span style={{ fontWeight: "bold" }}>Placed: </span>
                      {order.created}
                    </div>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2} style={{ marginTop: -10, marginLeft: -10 }}>
            {order.order_status === "Pending Payment" && (
              <div
                onClick={() => sendToOdos()}
                style={{
                  backgroundColor: "green",
                  fontSize: 16,
                  color: "white",
                  borderRadius: 20,
                  textAlign: "center",
                }}
              >
                {isPushing ? "Pushing" : "Push"}
              </div>
            )}
            <img src={appicon} width={86} height={86} alt="Dial a Delivery" />
          </Grid>
        </Grid>
      </DialogTitle>
      {order && (
        <Print
          ref={(myPrint) => (printRef = myPrint)}
          title=""
          otherStyle="@media print { html, body { height: initial !important; overflow: initial !important; }}"
        >
          <div style={{ display: "none" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td colSpan="10">
                    <div>
                      <div>{order.customer.name}</div>
                      <div>{callingNumber}</div>
                      <div>{order.customer.address}</div>
                      <div>Placed - {order.created}</div>
                      <div style={{ marginTop: 4 }}>
                        <div>Order No: {order.odos_order_number}</div>
                        <div>Order Instructions: {order.instructions}</div>
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <img
                      src={appicon}
                      width={86}
                      height={86}
                      alt="Dial a Delivery"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                marginTop: 40,
                display: "grid",
                gridTemplateColumns: "25% 25% 25% 25%",
                fontWeight: "bold",
                borderBottom: "1px solid black",
              }}
            >
              <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                <div>
                  <FontAwesomeIcon
                    icon="motorcycle"
                    color={order.drivers ? "#00A75C" : "inherit"}
                  />
                </div>
                <div></div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "10% 90%" }}>
                <div>
                  <FontAwesomeIcon icon="dollar-sign" />
                </div>
                <div>{order.payment?.payment_type}</div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                <div>
                  <FontAwesomeIcon icon="road" />
                </div>
                <div>{order.delivery_mode}</div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                <div>
                  <FontAwesomeIcon icon="user-edit" />
                </div>
                <div>{order.source}</div>
              </div>
            </div>
            {renderPrintItems()}
            <div
              style={{
                width: "100%",
                padding: 4,
                paddingBottom: 16,
                fontSize: 16,
                margin: 4,
              }}
            >
              <div style={{ display: "grid", gridTemplateColumns: "90% 10%" }}>
                <div>Vat</div>
                <div>{Number(order.order_tax_value)}</div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "90% 10%" }}>
                <div>Total</div>
                <div>{Number(order.order_total_price)}</div>
              </div>
            </div>
            <div
              style={{
                marginTop: 40,
                display: "grid",
                gridTemplateColumns: "45% 55%",
                fontWeight: "bold",
              }}
            >
              <div>
                <div
                  style={{
                    margin: 4,
                    display: "grid",
                    gridTemplateColumns: "21% 79%",
                  }}
                >
                  <div>Gratuity:</div>
                  <div style={{ borderBottom: "1px solid black" }}></div>
                </div>
                <div
                  style={{
                    marginTop: 25,
                    display: "grid",
                    gridTemplateColumns: "40% 60%",
                  }}
                >
                  <div>Total inc gratuity:</div>
                  <div style={{ borderBottom: "1px solid black" }}></div>
                </div>
                <div
                  style={{
                    marginTop: 25,
                    display: "grid",
                    gridTemplateColumns: "48% 52%",
                  }}
                >
                  <div>Customer Signature:</div>
                  <div style={{ borderBottom: "1px solid black" }}></div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 25,
                display: "grid",
                gridTemplateColumns: "70% 18% 12%",
                fontWeight: "bold",
              }}
            >
              <div style={{ display: "grid", gridTemplateColumns: "15% 85%" }}>
                <div>Comment:</div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "26% 74%" }}>
                <div>Date:</div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "45% 55%" }}>
                <div>Time:</div>
                <div style={{ borderBottom: "1px solid black" }}></div>
              </div>
            </div>
          </div>
        </Print>
      )}
      <DialogContent
        style={{
          paddingLeft: 48,
          paddingRight: 48,
        }}
      >
        <List style={{ fontSize: "0.9rem" }}>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="chart-bar"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={2.5}>
                <ListItemText
                  style={{ margin: 0, cursor: "pointer" }}
                  primary={order.complex_name}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="check-circle"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={2} align="center">
                <ListItemText
                  style={{
                    margin: 0,
                    backgroundColor: "#787a7c",
                    color: "#fff",
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                  primary={order.order_status}
                />
              </Grid>
              <Grid item sm={5}></Grid>
              <Grid item sm={3} align="right">
                <ListItemText
                  onClick={(event) => setAnchorHistoryEl(event.currentTarget)}
                  style={{
                    margin: 0,
                    marginRight: -40,
                    marginLeft: 45,
                    backgroundColor: "#787a7c",
                    color: "#fff",
                    borderRadius: 8,
                    paddingRight: 7,
                    cursor: "pointer",
                  }}
                  primary="Order History"
                />
                <Popover
                  open={Boolean(anchorHistoryEl)}
                  anchorEl={anchorHistoryEl}
                  onClose={() => setAnchorHistoryEl(null)}
                  className="popover_class"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {state().order_states.map((item, i) => (
                    <Progress
                      key={i}
                      height="80px"
                      gaap={item.gaap ? item.gaap : null}
                      time={
                        item.completedTime === "0:00" ? "" : item.completedTime
                      }
                      title={item.stateName}
                      mode={
                        item.completedTime === "0:00" ? "default" : "active"
                      }
                      last={state().order_states.length - i === 1}
                      first={
                        state().order_states.length - i > 1
                          ? state().order_states[i + 1].completedTime === "0:00"
                          : false
                      }
                      description={
                        item.statusDescription
                          ? item.statusDescription
                          : "You order is now in " + item.stateName + " stage"
                      }
                    />
                  ))}
                </Popover>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="motorcycle"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={3}>
                <ListItemText
                  style={{ margin: 0, cursor: "pointer" }}
                  primary={""}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="dollar-sign"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.payment_type}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="road"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={order.delivery_mode}
                />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="user"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText style={{ margin: 0 }} primary={order.source} />
              </Grid>
            </Grid>
          </ListItem>
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
              borderBottom: "thin solid #dddddd",
            }}
          >
            <Grid container>
              <Grid item sm={2} style={{ maxWidth: "8%" }}>
                <FontAwesomeIcon
                  icon="sticky-note"
                  style={{ width: "1.2em", height: "1.2em" }}
                />
              </Grid>
              <Grid item sm={10}>
                <ListItemText
                  style={{ margin: 0 }}
                  primary={`Order - ${order.odos_order_number}`}
                />
              </Grid>
            </Grid>
          </ListItem>
          {renderLineItems()}
          <ListItem
            style={{
              padding: 0,
              paddingBottom: 8,
              paddingTop: 8,
            }}
          >
            <Grid container>
              <Grid item sm={12} style={{ fontWeight: "bold" }}>
                <ListItemText style={{ margin: 0 }} primary="Total" />
                <ListItemSecondaryAction
                  style={{ marginTop: -10, top: "unset" }}
                >
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={
                      order.order_status === "Cart" && !order.order_total_price
                        ? Number(
                            order.line_tems.length > 0 &&
                              order.line_items[0].total
                          )
                        : Number(order.order_total_price)
                    }
                  />
                </ListItemSecondaryAction>
              </Grid>
              <Grid item sm={12}>
                <ListItemText style={{ margin: 0 }} primary="Vat" />
                <ListItemSecondaryAction
                  style={{ marginTop: -10, top: "unset" }}
                >
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={
                      order.order_status === "Cart" && !order.order_tax_value
                        ? Number(
                            order.line_items.length > 0 &&
                              order.line_items[0].total * tax
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : Number(order.order_tax_value).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                    }
                  />
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions
        style={{
          borderTop: "thin solid #dddddd",
        }}
      >
        {order.order_status.includes("Cancelled") ? null : (
          <Button
            onClick={() => printRef.onPrint()}
            color="primary"
            style={{
              textTransform: "capitalize",
              backgroundColor:
                order.order_status === "Cart" ? "#d71920" : "#bcbec0",
              color: order.order_status === "Cart" && "#ffff",
              padding: 0,
            }}
          >
            Print
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ) : (
    <div
      style={{
        position: "fixed",
        top: "50%",
        right: "47%",
      }}
    >
      <Loader type="Circles" color="#D71920" height="100" width="100" />
    </div>
  );
};

export default OrderDialog;
