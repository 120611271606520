import { BASE_URL } from "../app.config";
import { post } from "./fetch";

const logger = async (_function, operation, key, payload) => {
  const url = `${BASE_URL}/logger`;

  const data = {
    _function,
    operation,
    key,
    payload,
  };

  await post(url, data);
};

export default logger;
